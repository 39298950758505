import "./scss/WheelOfPrizes.scss";
import Lottie from 'react-lottie-player'
import confetti from '../animations/confetti.json'
import confettiSecondary from '../animations/confettiSecondary.json'
import chest from '../animations/chest.json'
import happyEmoji from '../animations/happyEmoji.json'

function WheelOfPrizesWon({ next, data, styling }) {
	console.log(data);
	return (
		<>
			<div style={{ marginTop: styling?.marginTop }} className="wheel_ofprizes-won">
				<div className="wheel__ofprizes-wonContent">
					<div className="wheel__ofprizes-chest mtop--20">
						<div dangerouslySetInnerHTML={{ __html: styling?.winMessage || 'Congratulations you won' }}></div>
						{styling.showPrizeImage &&
							<img className="wheel__ofprizes-chestPrize" src={data?.prize?.image} />
						}
						<h4 style={{ fontSize: '2em' }}>{data?.prize?.text}</h4>
						<div>{data?.prize?.description}</div>
						{/* <div className="wheel__ofprizes-wonBanner mtop--20">
							<div className="wheel__ofprizes-wonBannerLeft">
								<Lottie
									loop
									animationData={happyEmoji}
									play
									style={{ width: 50, height: 50 }}
								/>
							</div>
							<div className="wheel__ofprizes-wonBannerRight">
								YOU WON
							</div>
						</div> */}
					</div>
				</div>
				<div className="wheel__ofprizes-wonConfetti">
					<div className="wheel__ofprizes-wonConfettiA">
						<Lottie
							loop
							animationData={confettiSecondary}
							play
							style={{ width: '100%', height: '100%' }}
						/>
					</div>
					<Lottie
						loop
						animationData={confetti}
						play
						style={{ width: '100%', height: '100%' }}
					/>
				</div>
			</div>
			<div className="text--center" style={{ position: 'fixed', bottom: '64px', left: 0, right: 0 }} >
				<div className="btn btn-primary mtop--10 btn-lg wheel__ofprizes-next"
					style={{ background: styling?.buttonColor, color: styling?.textColor }}
					onClick={next}
				>
					Next
				</div>
			</div>
		</>
	);
};
export default WheelOfPrizesWon;
