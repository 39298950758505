import "./scss/WheelOfPrizes.scss";
import Lottie from 'react-lottie-player'
import lostAnimationOG from '../animations/neutral.json'

const hexToRGB = function (color) {
	if(typeof color !== 'string') return [0,0,0];
	color = color.replace('#', '');
	const MAX = color.length===3?15:255;
	const len = color.length===3?1:2;
	const r = parseInt(color.substring(0, len), 16);
	const g = parseInt(color.substring(len, len*2), 16);
	const b = parseInt(color.substring(len*2, len*3), 16);
	return [r/MAX, g/MAX, b/MAX];
}

const replaceColor = function(color, lostAnimationOG) {
	const c = hexToRGB(color);
	lostAnimationOG.assets.forEach(function(asset){
		asset.layers.forEach(function(layer){
			layer.shapes.forEach(function(shape){
				shape.it.forEach(function(it){
					if(it.mn?.toLowerCase().includes('fill')){
						it.c.k.forEach(function(k){
							k.s = c;
						});
					}
				});
			});
		});
	});
	return lostAnimationOG;
}

function WheelOfPrizesLost({ next, data, primaryTextColor }) {
	console.log("data?.textColor", data?.textColor)
	console.log("primaryTextColor", primaryTextColor)
	console.log("data?.textColor", data?.textColor)
	return (
		<>
			<div style={{ marginTop: data?.marginTop }} className="wheel_ofprizes-lost">
				<div dangerouslySetInnerHTML={{ __html: data?.lostMessage || 'Better luck next time!' }}></div>
				<div className="wheel_ofprizes-lostAnimation">
					<Lottie
							loop
							animationData={(data?.textColor || primaryTextColor)?replaceColor((data?.textColor || primaryTextColor), lostAnimationOG) : lostAnimationOG}
							play
							style={{ width: 300, height: 300, display: 'inline-block' }}
						/>
				</div>
			</div>
			<div className="text--center" style={{ position: 'fixed', bottom: '64px', left: 0, right: 0 }} >
				<div className="btn btn-primary btn-lg" style={{ background: data?.buttonColor, color: data?.textColor }} onClick={next}>
					Next
				</div>
			</div>
		</>
	);
};
export default WheelOfPrizesLost;
